/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState } from "react";

import { apiFetchProtected } from "../../../../../apiBindings";
import { Permission, useHoyerAcl } from "../../../../../hooks/useHoyerAcl";
import Box from "../../../../shared/Box";
import Columns, { LargeColumn } from "../../../../shared/Columns";
import FormError from "../../../../shared/forms/FormError";
import NewInput from "../../../../shared/forms/NewInput";
import HoyerAclError from "../../../../shared/HoyerAclError";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import { UncontrolledNotification as Notification } from "../../../../shared/Notification";
import HoyerCardLoadingIndicator from "../HoyerCardLoadingIndicator";
import * as styles from "./style.module.scss";

// eslint-disable-next-line @typescript-eslint/no-empty-function
const CustomerNumberRedeemer = ({ onSuccess = () => {} }) => {
    const { can } = useHoyerAcl();
    const [activating, setActivating] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [activationCode, setActivationCode] = useState("");

    const onSubmit = async (event) => {
        event.preventDefault();

        setActivating(true);
        setError(false);
        const data = await apiFetchProtected("/hoyercard-portal/activation", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify({
                customerNumber,
                activationCode,
            }),
        });

        setActivating(false);
        if (data.status === "ok") {
            setFinished(true);
            onSuccess();
        } else {
            setError(true);
            setErrorMessage(data.message);
        }
    };

    if (!can(Permission.CUSTOMER_ACTIVATE_CUSTOMER)) {
        return (
            <HoyerAclError message="Du hast keine Berechtigung Kundennummern zu aktivieren." />
        );
    }

    return (
        <Columns centered>
            <LargeColumn>
                <Box>
                    {finished && (
                        <>
                            <NewHeadline>Erfolgreich aktiviert</NewHeadline>
                            <Notification type="success">
                                <p>
                                    Sie haben Ihre Kundennummer erfolgreich
                                    aktiviert.
                                </p>
                            </Notification>
                        </>
                    )}
                    {!finished &&
                        can(Permission.CUSTOMER_ACTIVATE_CUSTOMER) && (
                            <>
                                <NewHeadline>
                                    Aktivieren Sie eine Kundennummer
                                </NewHeadline>
                                <form onSubmit={onSubmit}>
                                    <NewInput
                                        name="customer_number"
                                        value={customerNumber}
                                        validation="integer"
                                        placeholder="Kundennummer"
                                        onChange={(event) => {
                                            setCustomerNumber(
                                                event.target.value
                                            );
                                        }}
                                        required
                                    />
                                    <NewInput
                                        name="activation_code"
                                        value={activationCode}
                                        validation="activationCode"
                                        placeholder="Aktivierungscode"
                                        onChange={(event) =>
                                            setActivationCode(
                                                event.target.value
                                            )
                                        }
                                        required
                                    />
                                    {error && (
                                        <FormError message={errorMessage} />
                                    )}

                                    {activating ? (
                                        <HoyerCardLoadingIndicator>
                                            Kundennummer wird aktiviert
                                        </HoyerCardLoadingIndicator>
                                    ) : (
                                        <Button
                                            color="red"
                                            disabled={activating}
                                        >
                                            Aktivieren
                                        </Button>
                                    )}
                                </form>
                            </>
                        )}
                    {can(Permission.USER_REQUEST_ACTIVATION) && (
                        <div className={styles.noCode}>
                            <span>
                                Sie haben keinen Aktivierungscode erhalten?
                            </span>
                            <Button
                                color="blue"
                                text
                                to="/hoyercard-portal/request-activation"
                            >
                                Hier beantragen
                            </Button>
                        </div>
                    )}
                </Box>
            </LargeColumn>
        </Columns>
    );
};

export default CustomerNumberRedeemer;
