import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import { useDebounce } from "use-debounce";
import shallow from "zustand/shallow";

import { ApiError } from "../../../../apiBindings";
import { Permission, useHoyerAcl } from "../../../../hooks/useHoyerAcl";
import NewInput from "../../../shared/forms/NewInput";
import Button from "../../../shared/NewForms/Button";
import useSnackbar from "../../../shared/Snackbar/store";
import useDashboardStore from "../Dashboard/store";
import AddUserModal from "./AddUserModal";
import {
    addUserToCustomer,
    BaseUser,
    CustomerUserRole,
    getAvailableRoles,
    removeUserFromCustomer,
} from "./api";
import UserTable from "./CustomerUserTable";
import useCustomerUsersStore from "./store";

interface AccessProps {
    path: string;
}

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const SearchWrapper = styled.div`
    width: 100%;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Access: React.FC<AccessProps> = ({ path }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { can, getUser } = useHoyerAcl();
    const { confirm, notify } = useSnackbar();
    const [search, setSearch] = useState("");
    const [debouncedSearch] = useDebounce(search, 500);
    const [availableRoles, setAvailableRoles] = useState<CustomerUserRole[]>(
        []
    );

    useEffect(() => {
        const fetchAvailableRoles = async () => {
            try {
                const res = await getAvailableRoles();
                setAvailableRoles(res.data);
            } catch (error) {
                void notify(
                    "Fehlgeschlagen",
                    "Rollen konnten nicht geladen werden",
                    "error"
                );
            }
        };
        void fetchAvailableRoles();
    }, []);

    const {
        customerUsers,
        fetchCustomerUsers,
        loading,
        setLoading,
        error,
        paginationMeta,
        paginationLinks,
    } = useCustomerUsersStore(
        (state) => ({
            customerUsers: state.customerUsers,
            fetchCustomerUsers: state.fetchCustomerUsers,
            loading: state.loading,
            setLoading: state.setLoading,
            error: state.error,
            paginationMeta: state.paginationMeta,
            paginationLinks: state.paginationLinks,
        }),
        shallow
    );

    const { selectedCustomerNumber } = useDashboardStore(
        (state) => ({
            selectedCustomerNumber: state.selectedCustomerNumber,
        }),
        shallow
    );

    useEffect(() => {
        if (selectedCustomerNumber) {
            void fetchCustomerUsers(selectedCustomerNumber, 1, debouncedSearch);
        }
    }, [fetchCustomerUsers, selectedCustomerNumber, debouncedSearch]);

    const canDetachUser = (user: BaseUser) => {
        return (
            getUser()?.id !== user.id &&
            can(Permission.CUSTOMER_ACCESS_DISCONNECT_USER)
        );
    };

    const confirmDetach = async (user: BaseUser) => {
        if (!selectedCustomerNumber) {
            return notify(
                "Fehlgeschlagen",
                "Es wurde kein Kunde ausgewählt",
                "error"
            );
        }
        const confirmed = await confirm(
            "Benutzer entfernen",
            `Möchtest du den Benutzer ${user.name} entfernen?`
        );

        if (confirmed) {
            try {
                setLoading(true);
                const res = await removeUserFromCustomer(
                    selectedCustomerNumber,
                    user.id
                );
                void notify("Erfolgreich", res.message);
                void fetchCustomerUsers(selectedCustomerNumber, 1, search);
            } catch (error) {
                if (error instanceof ApiError) {
                    void notify("Fehlgeschlagen", error.message, "error");
                }
            } finally {
                setLoading(false);
            }
        }
    };

    const handleAddUser = async (email: string, userId: string) => {
        if (!selectedCustomerNumber) {
            return notify(
                "Fehlgeschlagen",
                "Es wurde kein Kunde ausgewählt",
                "error"
            );
        }
        try {
            setLoading(true);
            const res = await addUserToCustomer(
                selectedCustomerNumber,
                email,
                userId
            );
            if (res) {
                void notify("Einladung erfolgreich", res.message);
            }
        } catch (error) {
            if (error instanceof ApiError) {
                void notify("Einladung fehlgeschlagen", error.message, "error");
            }
        } finally {
            setLoading(false);
            setIsModalOpen(false);
        }
    };

    const handlePageChange = (newPage: number) => {
        if (selectedCustomerNumber) {
            void fetchCustomerUsers(selectedCustomerNumber, newPage);
        }
    };

    return (
        <>
            <GridContainer>
                <SearchWrapper>
                    <NewInput
                        type="text"
                        placeholder="Nach E-Mail-Adresse oder Name suchen."
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSearch(e?.target?.value.trim() ?? null)
                        }
                    ></NewInput>
                </SearchWrapper>
                <ButtonWrapper>
                    {can(Permission.CUSTOMER_ACCESS_CONNECT_USER) && (
                        <Button onClick={() => setIsModalOpen(true)}>
                            Neuen Benutzer einladen
                        </Button>
                    )}
                </ButtonWrapper>
            </GridContainer>
            <UserTable
                customerUsers={customerUsers}
                loading={loading}
                error={error}
                confirmDetach={confirmDetach}
                canDetachUser={canDetachUser}
                paginationLinks={paginationLinks}
                paginationMeta={paginationMeta}
                onPageChange={handlePageChange}
            />
            {can(Permission.CUSTOMER_ACCESS_CONNECT_USER) && (
                <AddUserModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onSubmit={handleAddUser}
                    loading={loading}
                    availableRoles={availableRoles}
                />
            )}
        </>
    );
};

export default Access;
